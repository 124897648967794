.no-border{
  border: none !important
}

/*****************  Spacers   **********************/

/*****************  Font Sizes  **********************/
.font-size-8{     font-size:8px; }
.font-size-9{     font-size:9px; }
.font-size-10{    font-size:10px;}
.font-size-11{    font-size:11px;}
.font-size-12{    font-size:12px;}
.font-size-13{    font-size:13px;}
.font-size-14{    font-size:14px;}
.font-size-15{    font-size:15px;}
.font-size-16{    font-size:16px;}
.font-size-17{    font-size:17px;}
.font-size-18{    font-size:18px;}
.font-size-20{    font-size:20px;}
.font-size-22{    font-size:22px;}
.font-size-24{    font-size:24px;}
.font-size-26{    font-size:26px;}
.font-size-32{    font-size:32px;}
.font-size-36{    font-size:36px;}
.font-size-48{    font-size:48px;}


.space-5{    height:5px;}
.space-10{    height:10px;}
.space-15{    height:15px;}
.space-20{    height:20px;}
.space-25{    height:25px;}
.space-30{    height:30px;}
.space-35{    height:35px;}
.space-40{    height:40px;}
.space-45{    height:45px;}
.space-50{    height:50px;}
.space-75{    height:75px;}
.space-100{    height:100px;}
.space-150{    height:150px;}
.space-175{    height:175px;}
.space-200{    height:200px;}


/*****************  custom helpers  **********************/

.no-margin{   margin: 0;}
.no-padding{    padding: 0;}
.no-round{    border-radius: 0; }

.text-right {text-align:right;}



.required-marker {
  float: right;
  margin-right: 0;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  color: red;
}

@media (max-width: 767px) {

  .text-center-xs{ text-align: center;}

}